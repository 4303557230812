import React, { useEffect, useState } from 'react';
import { GoogleLogin } from '@react-oauth/google';
import { googleAuthRequest } from 'services/http/auth';
import { navigate } from '../components/utils';
import { validateEmail } from 'global-utils/utils/validation';
import { Button, EmailInput, LOADER_ICON_SIZE, LoaderIcon, PasswordInput } from 'ui';
import Link from 'next/link';
import { useSession } from '../hooks/useSession';
import { LoginCredentials, useLoginMutation } from '../hooks';
import { toast } from 'react-hot-toast';
import { useIntercom } from 'react-use-intercom';
import { deriveError } from '../components/templates/utils';
import { BasicLayout } from '../components/onboarding/2.0';
import { useRouter } from 'next/router';

interface LoginFormState {
  email: string;
  password: string;
}
const defaultLoginFormState: LoginFormState = {
  email: '',
  password: '',
};

function Login() {
  const { handleSessionFromServer, selectedOrganization } = useSession();
  const { hardShutdown } = useIntercom();
  const [formState, setFormState] = useState<LoginCredentials>(defaultLoginFormState);
  const { mutateAsync: login, isLoading } = useLoginMutation();

  const validateForm = () => {
    if (!validateEmail(formState.email)) {
      toast.error('Please enter a valid email address.');
      return false;
    }
    if (!formState.password) {
      toast.error('Please enter your password.');
      return false;
    }
    return true;
  };

  const onLogin = async (credentials: LoginCredentials) => {
    if (!validateForm()) return;
    try {
      await login(credentials);
    } catch (error) {
      toast.error(deriveError(error));
    }
  };

  const handleGoogleAuth = async (credentialResponse) => {
    try {
      const response = await googleAuthRequest(credentialResponse);
      handleSessionFromServer(response.data);
    } catch (error: any) {
      toast.error(`Something went wrong, please try again later. ${error.message}`);
    }
  };
  const router = useRouter();
  useEffect(() => {
    if (selectedOrganization) {
      router.push('/overview');
      return;
    }
    hardShutdown();
  }, []);
  return (
    <BasicLayout>
      <div className='w-full h-full flex justify-center items-center'>
        <div className='w-[480px] py-8 px-12 border-authentication-card-border rounded-authentication-card border'>
          <div className='text-2xl text-black-1 font-medium'>Login</div>
          <div className='mt-12'>
            <GoogleLogin
              size='large'
              onSuccess={handleGoogleAuth}
              onError={() => {
                alert('Login Failed');
              }}
            />
          </div>
          <div className='flex justify-between items-center mt-6 mb-4'>
            <div className='w-full border-b border-[#888888]' />
            <p className='font-medium mx-2 text-[#888888] mb-1.5'>or</p>
            <div className='w-full border-b border-[#888888]' />
          </div>
          <div className='mb-1 font-medium'>Email</div>
          <EmailInput
            onEnter={() => (document.querySelector('input[type="password"]') as HTMLElement).focus()}
            value={formState.email}
            onChange={(e) => setFormState((prev) => ({ ...prev, email: e.target.value }))}
            data-cy='login_emailInput'
          />
          <div className='mt-4 mb-1 font-medium'>Password</div>
          <PasswordInput
            onEnter={() => onLogin(formState)}
            value={formState.password}
            onChange={(e) => setFormState((prev) => ({ ...prev, password: e.target.value }))}
            data-cy='login_passwordInput'
          />
          <div className='mt-4'>
            <Link className='text-gray-400 font-medium hover:opacity-80 duration-300' href={'/forgot-password'}>
              Forgot Password
            </Link>
          </div>
          <div className='flex flex-col gap-y-4 mt-4'>
            <Button onClick={() => onLogin(formState)} label='Continue' data-cy='login_continueButton' />
            <Button label='Create account' emphasis='low' onClick={() => navigate('/onboarding/new/create-account')} />
          </div>
          <Link href='/terms' className='mt-10'>
            <span className='text-[#0000FF] block text-center mt-2'>Terms of service</span>
          </Link>
          <Link href='/privacy'>
            <span className='text-[#0000FF] block text-center mt-2'>Privacy policy</span>
          </Link>
        </div>
        {isLoading && (
          <div className='top-0 fixed w-screen h-screen flex justify-center items-center bg-[#ffffff87] z-10'>
            <LoaderIcon size={LOADER_ICON_SIZE.LARGE} />
          </div>
        )}
      </div>
    </BasicLayout>
  );
}

export default Login;
